import ReactDOM from 'react-dom';

export const replaceNodeWithReactComponent = ({
  elementToBeReplaced,
  wrapper = document.createElement('div'),
  reactComponent,
}) => {
  elementToBeReplaced.replaceWith(wrapper);
  ReactDOM.render(reactComponent, wrapper);
};

export const addAttributeToElement = ({ element, attribute, value }) => {
  element.setAttribute(attribute, value);
};

export const addTargetBlankToLinks = (elementList) => {
  if (elementList) {
    elementList.forEach((element) => {
      addAttributeToElement({ element, attribute: 'target', value: '_blank' });
    });
  }
};
