import * as React from 'react';

import { Container, Img } from './styles';

import Resource from '../Resource';

const CaseStudy = ({ caseStudy, ...props }) => {
  return (
    <Container {...props}>
      <Img src={caseStudy?.media[0].url} />
      <Resource full type={caseStudy.library_types[0].name} resource={caseStudy} />
    </Container>
  );
};

export default CaseStudy;
