import styled from 'styled-components';
import { BREAKPOINTS, COLORS, H1, H5, Ps, H6 } from '../../../utils/theme';

import IconPlus from '../../IconPlus';

const WEIGHTS = {
  DESKTOP: 5,
};

const calculateFlexBasis = (weight, totalWeight) => (weight * 100) / totalWeight;

export const Container = styled.div`
  background-color: ${COLORS.BACKGROUND_LIGHT_2};
  padding-top: 100px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding-top: 40px;
    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

export const Title = styled(H1)`
  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: -0.19px;
    margin-top: 10px;
  }
`;

export const Desc = styled.div`
  margin-bottom: 20px;
  color: ${COLORS.BLACK};
  font-size: 20px;
  font-weight: normal;
  opacity: 0.9;
  line-height: 32px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    margin-top: 30px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.16px;
  }
`;

export const Subdesc = styled(Ps)`
  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.14px;
    color: #6b6865;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 240px;
  background-position: center;
  background-size: cover;
  position: relative;
  cursor: pointer;

  span {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    width: 100%;
  }
`;

export const Tag = styled(H5)`
  border-radius: 30px;
  padding: 10px 15px;
  width: max-content;
  border: 1px solid red;
  color: red;
  margin-bottom: 10px;
`;

export const DataWorld = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 64px;
  padding-right: 64px;
  border-left: 1px solid ${COLORS.BLACK};
  box-sizing: border-box;
  margin-bottom: 50px;

  ${(props) =>
    props.weight &&
    `
        flex-basis: ${calculateFlexBasis(props.weight, WEIGHTS.DESKTOP)}%;

        ${Desc} {
            ${props.weight === 3 && `max-width: 60%;`}

            @media (max-width: ${BREAKPOINTS.PHABLET}px) {
                max-width: 100%;
            }
        }
        ${Subdesc} {
            ${props.weight === 2 ? `max-width: 70%;` : ''}
            ${props.weight === 3 ? `max-width: 60%;` : ''}

            @media (max-width: ${BREAKPOINTS.PHABLET}px) {
                max-width: 90%;
            }
        }
    `}

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex: 1;
    padding: 0 34px;
    margin-bottom: 50px;
  }

  a {
    color: ${COLORS.BLACK};
  }
`;

export const Plus = styled(IconPlus)`
  width: 3.75rem;
  height: 3.75rem;
  margin-left: -1rem;
`;

export const MoreLoadRow = styled.button`
  cursor: pointer;
  display: inline-flex;
  max-width: max-content;
  align-items: center;
  padding: 0rem 1.7rem;
  border-radius: 3rem;
  margin-top: 32px;

  background: none;

  margin-left: 1rem;

  border: 1.5px solid ${COLORS.BLUE};
  color: ${COLORS.BLUE};

  &:focus-visible {
    outline: 2px solid ${COLORS.BLUE};
  }
  &:hover {
    opacity: 0.7;
  }

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    margin-left: 0;
  }
`;

export const MoreLoadText = styled(H6)`
  margin-left: 15px;
  color: ${COLORS.BLUE};

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    opacity: 0.9;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.3px;
  }
`;

export const PopupContainer = styled.div`
  position: relative;
`;

export const PopupClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  color: black;
  border: 0;
  font-size: 26px;
  cursor: pointer;
  padding: 0px 15px;
`;

export const PopupTitle = styled.h3`
  border-bottom: 1px solid black;
  margin: 0;
  padding: 5px 10px 15px;
`;

export const PopupContent = styled.div`
  padding: 10px;
  overflow: auto;
  max-height: 80vh;
  display: flex;

  img {
    max-width: 100%;
    height: 100%;
  }
`;
