import styled from 'styled-components';
import { SIDE_PADDING } from '../../../utils/sizes';
import { BREAKPOINTS, COLORS } from '../../../utils/theme';
import { Row as RowComponent } from '../../Row/styles';

import { H6 } from '../../../utils/theme';

import IconPlus from '../../IconPlus';

import PinkTexture1 from './pink-textures-1.png';
import PinkTexture2 from './pink-textures-2.png';

export const Container = styled.div`
  position: relative;
  background-color: ${COLORS.BACKGROUND_LIGHT_2};
  box-shadow: inset 0px 32px 64px 0px rgba(0, 0, 0, 0.05);
  padding-top: 45px;
  padding-bottom: 100px;
  padding-left: ${SIDE_PADDING};
  padding-right: ${SIDE_PADDING};

  &:before {
    content: '';
    background-image: url(${PinkTexture1});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    position: absolute;
    width: 220px;
    height: 230px;
    left: 332px;
    top: 4px;
    z-index: 0;
  }

  &:after {
    content: '';
    background-image: url(${PinkTexture2});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    position: absolute;
    width: 240px;
    height: 300px;
    right: 320px;
    top: 630px;
    z-index: 0;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    padding: 55px 16px;
  }
`;

export const Row = styled(RowComponent)`
  position: inherit;
  z-index: 2;
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const Plus = styled(IconPlus)`
  width: 3.75rem;
  height: 3.75rem;
  margin-left: -1rem;
`;

export const MoreLoadRow = styled.button`
  cursor: pointer;
  display: inline-flex;
  max-width: max-content;
  align-items: center;
  padding: 0rem 1.7rem;
  border-radius: 3rem;
  margin-top: 32px;

  background: none;

  margin-left: 0px;

  border: 1.5px solid ${COLORS.BLUE};
  color: ${COLORS.BLUE};

  &:focus-visible {
    outline: 2px solid ${COLORS.BLUE};
  }
  &:hover {
    opacity: 0.7;
  }

  @media (min-width: ${BREAKPOINTS.PHABLET}px) {
    margin-left: 25px;
  }
`;

export const MoreLoadText = styled(H6)`
  margin-left: 15px;
  color: ${COLORS.BLUE};

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    opacity: 0.9;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.3px;
  }
`;
