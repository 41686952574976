import styled from 'styled-components';
import { BREAKPOINTS, COLORS, H3, H5, Ps } from '../../../utils/theme';

export const Container = styled.div`
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.BLACK};
  padding: 25px;
  height: 405px;
  flex-basis: 30%;
  box-sizing: border-box;
  overflow: hidden;

  ${(props) => props.full && `flex: 1;`}

  @media (max-width: ${BREAKPOINTS.TABLET}px) {
    flex-basis: 50%;
    margin: 0;
    margin-bottom: 16px;
    height: auto;
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex-basis: 100%;
  }
`;

export const OpenInNewWindowIcon = styled.span`
  background-image: url('../../../images/external-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 5px;
  position: relative;
  top: 2px;
  opacity: 0.8;
`;

export const ScrolledContainer = styled.div`
  height: 335px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 5px;

  ${(props) =>
    props.withLink &&
    `
      height: 310px;
    `}
`;

export const Link = styled.a`
  color: ${COLORS.BLACK};
`;

export const Type = styled(H5)`
  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: 0.3px;
  }
`;

export const Title = styled(H3)`
  margin-bottom: 30px;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: -0.19px;
  }
`;

export const Description = styled.div`
  font-size: 1.1rem;
  color: ${COLORS.BLACK};
  font-weight: 400;
  line-height: 1.6;
  & > p {
    font-size: 1.1rem;
    font-weight: 400;
  }

  @media (min-width: ${BREAKPOINTS.TABLET}px) {
    font-size: 1.3rem;
    & > p {
      font-size: 1.3rem;
    }
  }
`;

export const Desc = styled(Ps)`
  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.16px;
  }
`;
