import styled from 'styled-components';
import { COLORS, BREAKPOINTS } from '../../utils/theme';

export const Title = styled.p`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  box-shadow: inset 0px 32px 64px 0px rgba(0, 0, 0, 0.05);
  margin: 0;
  padding: 30px 0;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    font-size: 14px;
    font-weight: 500;
    padding: 24px 0;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 0;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px 32px 64px 0px rgba(0, 0, 0, 0.05);
  min-width: 100%;
  overflow: auto;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    justify-content: start;
  }
`;

export const FiltersButtonMobile = styled.button`
  display: none;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    display: flex;
    border: none;
    font-size: 16px;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    background-color: ${COLORS.BLUE};
    color: ${COLORS.WHITE};
    border-radius: 15px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    width: 105px;
    height: 46px;
    border-radius: 28px;
    font-weight: normal;
  }
`;

export const Filter = styled.button`
  display: flex;
  border: none;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 15px 20px;
  margin: 0 5px;
  background-color: ${COLORS.LIGHT_GREY};
  color: ${COLORS.BLACK};
  border-radius: 15px;
  cursor: pointer;
  white-space: nowrap;

  ${(props) =>
    props.selected &&
    `
        background-color: ${COLORS.BLUE};
        color: ${COLORS.WHITE};
    `}
`;
