import styled from 'styled-components';
import { BREAKPOINTS } from '../../utils/theme';

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${(props) => (props.rightAligned ? 'justify-content: flex-end;' : '')}
  ${(props) => (props.marginTop ? `margin-top: ${props.marginTop};` : '')}
  ${(props) => (props.marginRight ? `margin-right: ${props.marginRight};` : '')}
  ${(props) => (props.marginBottom ? `margin-bottom: ${props.marginBottom};` : '')}
  ${(props) => props.noSpace && 'justify-content: normal;'}
  ${(props) => props.noFlex && 'display: inline-block;'}
  ${(props) =>
    props.flexDirectionMobile &&
    `
    @media (max-width: ${BREAKPOINTS.PHABLET}px) {
      flex-direction: ${props.flexDirectionMobile};
      align-items: center;
    }
  `}
`;
