import * as React from 'react';

import { Title, FiltersContainer, Filter } from './styles';

const Filters = ({ title, filters = [], apply = null }) => {
  const [selected, setSelected] = React.useState(null);

  const onSelectFilter = (id) => {
    setSelected(id);
    if (apply) {
      apply(id);
    }
  };

  return (
    <>
      {title && <Title>{title}</Title>}
      <FiltersContainer>
        <Filter selected={!selected} onClick={() => onSelectFilter(null)}>
          All
        </Filter>
        {filters.map((filter) => (
          <Filter
            onClick={() => onSelectFilter(filter.id)}
            selected={filter.id === selected}
            key={filter.id}
          >
            {filter.text}
          </Filter>
        ))}
      </FiltersContainer>
    </>
  );
};

export default Filters;
