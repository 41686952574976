import styled from 'styled-components';
import { BREAKPOINTS, COLORS } from '../../../utils/theme';

export const Img = styled.img`
  object-fit: cover;
  position: absolute;
  height: 250px;
  width: 100%;
  top: 0;
  left: 0;
  transition-property: top, left;
  transition-duration: 0.1s;
  opacity: 1;
  z-index: -1;
  pointer-events: none;

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    top: -200px;
  }
`;

export const Container = styled.div`
  position: relative;
  background-color: ${COLORS.WHITE};
  height: 405px;
  box-sizing: border-box;
  display: flex;
  flex-basis: 30%;

  @media (min-width: ${BREAKPOINTS.PHABLET + 1}px) {
    &:hover {
      z-index: 10;
      ${Img} {
        top: -200px;
        left: 40px;
      }
    }
  }

  @media (max-width: ${BREAKPOINTS.PHABLET}px) {
    flex-basis: 100%;
    margin-top: 200px;
  }
`;
