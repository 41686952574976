import * as React from 'react';

import {
  Container,
  ScrolledContainer,
  Type,
  Title,
  Description,
  Link,
  OpenInNewWindowIcon,
} from './styles';

const buildType = (type) => (type === 'CASE_STUDY' ? 'CASE STUDY' : type).toUpperCase();

const Resource = ({ type = 'OTHER', resource, ...props }) => (
  <Container {...props}>
    <Type>{buildType(type)}</Type>
    <ScrolledContainer withLink={!!resource?.link}>
      <Title>{resource?.title}</Title>
      <Description dangerouslySetInnerHTML={{ __html: resource?.description }}></Description>
    </ScrolledContainer>
    {resource?.link && (
      <Link href={resource?.link} target={resource?.openinnewwindow ? '_blank' : '_self'}>
        {resource?.linktext}
        {resource?.openinnewwindow && <OpenInNewWindowIcon />}
      </Link>
    )}
  </Container>
);

export default Resource;
